import { useEffect, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { Button, ButtonsContainer, ActionsContainer } from './Actions.style'
import {
  DICOM_LABELING_JOBS,
  PHASE_LABELS,
  KEYPOINT_COLLECTION_LABELS,
  VIEW_LABELS,
  SEGMENTATION_LABELS,
  QUALITY_LABELS,
} from '../../../../../constants/api'
import { useSelector } from 'react-redux'

const Actions = ({
  dicom,
  frames,
  phaseLabels,
  keypointCollectionLabels,
  segmentations,
  viewLabel,
  qualityLabel,
  setDataIsSaving,
  setErrorOccured,
  setViewLabelChangesAreSaved,
  setQualityLabelChangesAreSaved,
  setPhaseLabelLabelChangesAreSaved,
  setKeypointCollectionLabelLabelsChangesAreSaved,
  setSegmentationChangesAreSaved,
  setDicomJobChangesAreSaved,
  checkIfDicomIsLabeled,
  dicomLabelingJob,
}) => {
  const user = useSelector((state) => state.auth.user)
  const { annotationSetId } = useParams()

  const [{ loading: upsertDicomJobIsLoading }, upsertDicomJob] = useAxios(DICOM_LABELING_JOBS, {
    manual: true,
    autoCancel: false,
  })

  const [{ loading: upsertViewLabelIsLoading }, upsertViewLabel] = useAxios(VIEW_LABELS, {
    manual: true,
    autoCancel: false,
  })

  const [{ loading: upsertQualityLabelIsLoading }, upsertQualityLabel] = useAxios(QUALITY_LABELS, {
    manual: true,
    autoCancel: false,
  })

  const [{ loading: upsertPhaseLabelsIsLoading }, upsertPhaseLabels] = useAxios(PHASE_LABELS, {
    manual: true,
    autoCancel: false,
  })

  const [{ loading: upsertKeypointCollectionsIsLoading }, upsertKeypointCollections] = useAxios(
    KEYPOINT_COLLECTION_LABELS,
    {
      manual: true,
      autoCancel: false,
    },
  )

  const [{ loading: upsertSegmentationsIsLoading }, upsertSegmentations] = useAxios(SEGMENTATION_LABELS, {
    manual: true,
    autoCancel: false,
  })

  const viewLabelPayload = useMemo(() => {
    if (!viewLabel?.viewClass) return null
    return {
      viewClass: viewLabel?.viewClass,
      dicom: dicom?.uuid,
    }
  }, [dicom?.uuid, viewLabel?.viewClass])

  const qualityLabelPayload = useMemo(() => {
    if (!qualityLabel?.qualityClass) return null
    return qualityLabel
  }, [qualityLabel])

  const phaseLabelsPayload = useMemo(
    () => phaseLabels?.filter((phaseLabel) => phaseLabel?.dicom === dicom?.uuid && !!phaseLabel?.phaseClass),
    [dicom?.uuid, phaseLabels],
  )

  // x, y for some reason are no longer ints, hence converted here
  const keypointCollectionLabelsPayload = useMemo(() => {
    const frameUuids = frames?.map((frame) => frame?.uuid)
    return keypointCollectionLabels
      ?.filter((keypointCollectionLabel) => frameUuids?.includes(keypointCollectionLabel?.frame))
      ?.map((keypointCollectionLabel) => {
        return {
          ...keypointCollectionLabel,
          keypointLabels: keypointCollectionLabel?.keypointLabels?.map((keypointLabel) => ({
            ...keypointLabel,
            x: Math.round(keypointLabel?.x),
            y: Math.round(keypointLabel?.y),
          })),
        }
      })
  }, [frames, keypointCollectionLabels])

  const segmentationsPayload = useMemo(() => {
    const frameUuids = frames?.map((frame) => frame?.uuid)
    return segmentations?.filter((segmentation) => frameUuids?.includes(segmentation?.frame))
  }, [frames, segmentations])

  const dicomJobPayload = useMemo(() => {
    const { dicomIsLabeled } = checkIfDicomIsLabeled(dicom)
    return {
      dicom: dicom?.uuid,
      annotationSet: annotationSetId,
      labeled: dicomIsLabeled,
    }
  }, [annotationSetId, checkIfDicomIsLabeled, dicom])

  const dataIsSaving = useMemo(
    () =>
      upsertViewLabelIsLoading ||
      upsertQualityLabelIsLoading ||
      upsertPhaseLabelsIsLoading ||
      upsertKeypointCollectionsIsLoading ||
      upsertSegmentationsIsLoading ||
      upsertDicomJobIsLoading,
    [
      upsertKeypointCollectionsIsLoading,
      upsertQualityLabelIsLoading,
      upsertViewLabelIsLoading,
      upsertPhaseLabelsIsLoading,
      upsertSegmentationsIsLoading,
      upsertDicomJobIsLoading,
    ],
  )

  const handleSaveOnClick = () => {
    if (user?.isSuperuser) return window.confirm('Save is disabled for admin')
    setDataIsSaving(true)

    if (viewLabelPayload)
      upsertViewLabel({
        url: VIEW_LABELS,
        data: viewLabelPayload,
        method: 'POST',
      })
        ?.then((r) => setViewLabelChangesAreSaved(true))
        ?.catch((e) => {
          console.log(e)
          setErrorOccured(true)
        })

    if (qualityLabelPayload)
      upsertQualityLabel({
        url: QUALITY_LABELS,
        data: qualityLabel,
        method: 'POST',
      })
        ?.then((r) => setQualityLabelChangesAreSaved(true))
        ?.catch((e) => {
          console.log(e)
          setErrorOccured(true)
        })

    upsertPhaseLabels({
      url: PHASE_LABELS,
      data: phaseLabelsPayload,
      method: 'POST',
    })
      ?.then((r) => setPhaseLabelLabelChangesAreSaved(true))
      ?.catch((e) => {
        console.log(e)
        setErrorOccured(true)
      })

    upsertKeypointCollections({
      url: KEYPOINT_COLLECTION_LABELS,
      data: keypointCollectionLabelsPayload,
      method: 'POST',
    })
      ?.then((r) => setKeypointCollectionLabelLabelsChangesAreSaved(true))
      ?.catch((e) => {
        console.log(e)
        setErrorOccured(true)
      })

    upsertSegmentations({
      url: SEGMENTATION_LABELS,
      data: segmentationsPayload,
      method: 'POST',
    })
      ?.then((r) => setSegmentationChangesAreSaved(true))
      ?.catch((e) => {
        console.log(e)
        setErrorOccured(true)
      })

    upsertDicomJob({
      url: `${DICOM_LABELING_JOBS}${dicomLabelingJob}/`,
      data: dicomJobPayload,
      method: 'PATCH',
    })
      ?.then((r) => setDicomJobChangesAreSaved(true))
      ?.catch((e) => {
        console.log(e)
        setErrorOccured(true)
      })
  }

  useEffect(() => setDataIsSaving(dataIsSaving), [dataIsSaving, setDataIsSaving])

  const handleSkipOnClick = () => {}

  useHotkeys('p', (event) => {
    event?.preventDefault()
    handleSaveOnClick()
  })

  useHotkeys('L', (event) => {
    event?.preventDefault()
    handleSkipOnClick()
  })

  return (
    <ActionsContainer>
      <ButtonsContainer>
        <Button disabled={dataIsSaving} onClick={handleSaveOnClick}>
          (P) Save
        </Button>
        <Button disabled={dataIsSaving} onClick={handleSkipOnClick}>
          (L) Skip
        </Button>
      </ButtonsContainer>
    </ActionsContainer>
  )
}

export default Actions
