import { memo, useMemo } from 'react'

import KeypointOption from './KeypointOption'
import PhaseOption from './PhaseOption'
import {
  LabelsContainer,
  FeatureContainer,
  FeatureOptionsContainer,
  FeatureTitle,
  TitleContainer,
} from './Labels.style'

import phaseClasses from './phaseClasses.json'

const Labels = ({
  activeFrame,
  systolicFrame,
  diastolicFrame,
  activeKeypoints,
  setSystolicFrame,
  setDiastolicFrame,
  setKeypoints,
}) => {
  const phaseOptions = useMemo(
    () =>
      phaseClasses?.map((phaseClass, index) => {
        const hotkey = phaseClass?.[0]
        const setPhase = phaseClass === 'Systolic' ? setSystolicFrame : setDiastolicFrame
        const isSelected =
          phaseClass === 'Systolic'
            ? activeFrame?.index === systolicFrame?.index
            : activeFrame?.index === diastolicFrame?.index
        return (
          <PhaseOption key={index} hotkey={hotkey} label={phaseClass} setPhase={setPhase} isSelected={isSelected} />
        )
      }),
    [activeFrame?.index, diastolicFrame?.index, setDiastolicFrame, setSystolicFrame, systolicFrame?.index],
  )

  const keypointClassOptions = useMemo(
    () =>
      activeKeypoints?.map((keypoint) => {
        const keypointIndex = keypoint?.keypointClass?.order - 1

        const setKeypointIsActive = (isActive) => {
          const newKeypoints = activeKeypoints?.map((keypoint) => ({ ...keypoint, isActive: false }))
          setKeypoints([
            ...newKeypoints?.slice(0, keypointIndex),
            { ...newKeypoints[keypointIndex], isActive },
            ...newKeypoints?.slice(keypointIndex + 1),
          ])
        }

        return (
          <KeypointOption
            key={keypoint?.keypointClass?.uuid}
            keypoint={keypoint}
            setKeypointIsActive={setKeypointIsActive}
          />
        )
      }),
    [activeKeypoints, setKeypoints],
  )

  return (
    <LabelsContainer>
      {/* <TitleContainer>Labels</TitleContainer> */}
      <FeatureContainer>
        <FeatureTitle>Phase</FeatureTitle>
        <FeatureOptionsContainer>{phaseOptions}</FeatureOptionsContainer>
      </FeatureContainer>
      <FeatureContainer>
        <FeatureTitle>Keypoints</FeatureTitle>
        <FeatureOptionsContainer>{keypointClassOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
