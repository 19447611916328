import {
  Button,
  ButtonsContainer,
  IdentifierContainer,
  Title,
  TitleContainer,
  ViewsContainer,
  WindowsContainer,
} from './Identifier.style'

const Identifier = ({ dicom, viewClasses, viewLabel, setViewLabel }) => {
  const type = dicom?.type
  const uniqueWindows = [...new Set(viewClasses?.map((viewClass) => viewClass?.window))]

  const windowOrders = ['Long Axis', 'Short Axis', 'Apical', 'Subcostal', 'Suprasternal', 'Miscellaneous']
  const orderedWindows = uniqueWindows.sort((a, b) => {
    const stringA = a.replace(' Color', '')
    const stringB = b.replace(' Color', '')
    const indexA = windowOrders.indexOf(stringA)
    const indexB = windowOrders.indexOf(stringB)

    return indexA - indexB
  })

  const buttons = orderedWindows?.map((window, windowIndex) => {
    const filteredviewClasses = viewClasses
      ?.filter((viewClass) => viewClass?.type === type)
      ?.filter((viewClass) => viewClass?.window === window)
      ?.map((viewClass, viewIndex) => {
        const handleViewClassOnClick = () =>
          setViewLabel({
            ...viewLabel,
            dicom: dicom?.uuid,
            viewClass: viewClass?.uuid,
          })

        return (
          <Button key={viewIndex} onClick={handleViewClassOnClick} $isActive={viewLabel?.viewClass === viewClass?.uuid}>
            {viewClass.view}
          </Button>
        )
      })

    return (
      <ViewsContainer key={windowIndex}>
        <Title>{window}</Title>
        <ButtonsContainer>{filteredviewClasses}</ButtonsContainer>
      </ViewsContainer>
    )
  })

  return (
    <IdentifierContainer>
      <TitleContainer>Select View</TitleContainer>
      <WindowsContainer>{buttons}</WindowsContainer>
    </IdentifierContainer>
  )
}

export default Identifier
