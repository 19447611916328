import styled from 'styled-components'

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #ffffff;
`
