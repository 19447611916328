import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import DataTable from 'react-data-table-component'
import useAxios from 'axios-hooks'

import Loader from './Loader'
import { ANNOTATION_SETS } from '../../../../constants/api'
import {
  FactContainer,
  FactTitle,
  SideBar,
  StyledInput,
  SubtitleContainer,
  TableContainer,
  TableWrapper,
  TitleContainer,
} from './Table.style'
import './Table.style.css'

const Table = ({ studyCollection, studyCollectionsResponseIsLoading }) => {
  const { studyCollectionId } = useParams()

  const [search, setSearch] = useState(null)

  const columns = useMemo(
    () => [
      {
        name: 'UUID',
        sortable: true,
        cell: (row) => (
          <Link to={`/collections/${studyCollectionId}/${row?.uuid}`} className="data-link">
            {row.uuid}
          </Link>
        ),
      },
      {
        name: 'Name',
        sortable: true,
        cell: (row) => (
          <Link to={`/collections/${studyCollectionId}/${row?.uuid}`} className="data-link">
            {row.name}
          </Link>
        ),
      },
      {
        name: 'Jobs',
        sortable: true,
        cell: (row) => (
          <Link to={`/collections/${studyCollectionId}/${row?.uuid}`} className="data-link">
            {row.numberOfJobs}
          </Link>
        ),
      },
      {
        name: 'Labeled',
        sortable: true,
        cell: (row) => (
          <Link to={`/collections/${studyCollectionId}/${row?.uuid}`} className="data-link">
            {row.numberOfLabeledJobs}
          </Link>
        ),
      },
      {
        name: 'Skipped',
        sortable: true,
        cell: (row) => (
          <Link to={`/collections/${studyCollectionId}/${row?.uuid}`} className="data-link">
            {row.numberOfSkippedJobs}
          </Link>
        ),
      },
      {
        name: 'Remaining',
        sortable: true,
        cell: (row) => (
          <Link to={`/collections/${studyCollectionId}/${row?.uuid}`} className="data-link">
            {row?.numberOfJobs - row?.numberOfLabeledJobs - row?.numberOfSkippedJobs}
          </Link>
        ),
      },
    ],
    [studyCollectionId],
  )

  const params = useMemo(
    () => ({
      study_collection__uuid: studyCollectionId,
      paginate: false,
    }),
    [studyCollectionId],
  )

  const [{ data, loading }] = useAxios(
    {
      url: ANNOTATION_SETS,
      params,
    },
    {
      autoCancel: false,
      useCache: false,
    },
  )

  const isLoading = studyCollectionsResponseIsLoading || loading
  const count = useMemo(() => data?.length, [data])
  const results = useMemo(() => {
    if (!search) return data
    return data?.filter(
      (annotationSet) =>
        annotationSet?.uuid?.toLowerCase()?.includes(search?.toLowerCase()) ||
        annotationSet?.dicom?.toLowerCase()?.includes(search?.toLowerCase()),
    )
  }, [data, search])

  const handleSearchOnChange = (e) => setSearch(e.target.value)

  return (
    <TableContainer>
      <SideBar>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TitleContainer>{studyCollection?.name}</TitleContainer>
            <SubtitleContainer>
              <FactTitle>Collection Summary</FactTitle>
              <FactContainer>
                <div>Total Studies</div>
                <div>{studyCollection?.numberOfStudies}</div>
              </FactContainer>
              <FactContainer>
                <div>Total Annotation Sets</div>
                <div>{studyCollection?.numberOfAnnotationSets}</div>
              </FactContainer>
            </SubtitleContainer>
          </>
        )}
      </SideBar>
      <TableWrapper>
        <DataTable
          columns={columns}
          data={results}
          pagination
          paginationTotalRows={count}
          paginationDefaultPage={1}
          progressPending={isLoading}
          progressComponent={<Loader />}
          persistTableHead
        />
        {!isLoading && <StyledInput placeholder="Search..." value={search || ''} onChange={handleSearchOnChange} />}
      </TableWrapper>
    </TableContainer>
  )
}

export default Table
