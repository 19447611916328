import { memo, useMemo } from 'react'

import KeypointOption from './KeypointOption'
import {
  Checkbox,
  FeatureContainer,
  FeatureOptionsContainer,
  FeatureTitle,
  LabelsContainer,
  OptionContainer,
} from './Labels.style'

import KeypointCollectionOption from './KeypointCollectionOption'
import PhaseOption from './PhaseOption'

const Labels = ({
  activeFrame,
  activeFrameIndex,
  phaseLabels,
  setActiveFrameIndex,
  activeKeypoints,
  setKeypoints,
  keypointCollectionClasses,
  activeKeypointCollectionClass,
  setActiveKeypointCollectionClass,
  keypointCollectionLabels,
  showPhaser,
}) => {
  const phaseLabelOptions = phaseLabels?.map((phaseLabel, index) => {
    const isActive = activeFrameIndex === phaseLabel?.frameIndex
    const keypointCollectionClassUuids = keypointCollectionClasses?.map(
      (keypointCollectionClass) => keypointCollectionClass?.uuid,
    )
    const phaseLabelKeypointCollections = keypointCollectionLabels?.filter(
      (keypointCollectionLabel) =>
        keypointCollectionClassUuids?.includes(keypointCollectionLabel?.keypointCollectionClass) &&
        keypointCollectionLabel?.frame === phaseLabel?.frame,
    )

    const numberOfKeypointsInPhase = keypointCollectionClasses
      ?.map((keypointCollectionClass) => keypointCollectionClass?.keypointClasses?.length)
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)
    const phaseKeypoints = phaseLabelKeypointCollections?.flatMap(
      (keypointCollectionLabel) => keypointCollectionLabel?.keypointLabels,
    )
    const isLabeled =
      numberOfKeypointsInPhase === phaseKeypoints?.filter((keypoint) => keypoint?.x && keypoint?.y)?.length &&
      phaseLabelKeypointCollections?.length === keypointCollectionClasses?.length

    const handlePhaseOptionOnClick = () => setActiveFrameIndex(phaseLabel?.frameIndex)

    return (
      <PhaseOption
        key={index}
        phaseLabel={phaseLabel}
        isActive={isActive}
        isLabeled={isLabeled}
        handlePhaseOptionOnClick={handlePhaseOptionOnClick}
      />
    )
  })

  const keypointCollectionClassOptions = useMemo(() => {
    const keypointCollectionHotkeys = ['Q', 'W', 'E', 'R', 'T', 'Y']
    return keypointCollectionClasses?.map((keypointCollectionClass, index) => {
      const isActive = activeKeypointCollectionClass?.uuid === keypointCollectionClass?.uuid
      const keypointCollectionLabel = keypointCollectionLabels?.find(
        (keypointCollectionLabel) =>
          keypointCollectionLabel?.keypointCollectionClass === keypointCollectionClass?.uuid &&
          keypointCollectionLabel?.frame === activeFrame?.uuid,
      )
      const keypointLabels = keypointCollectionLabel?.keypointLabels
      const isLabeled = keypointLabels?.length > 0 && keypointLabels?.every((keypoint) => keypoint?.x && keypoint?.y)
      return (
        <KeypointCollectionOption
          key={keypointCollectionClass?.uuid}
          hotkey={keypointCollectionHotkeys[index]}
          isActive={isActive}
          isLabeled={isLabeled}
          keypointCollectionClass={keypointCollectionClass}
          setActiveKeypointCollectionClass={setActiveKeypointCollectionClass}
        />
      )
    })
  }, [
    activeFrame?.uuid,
    activeKeypointCollectionClass?.uuid,
    keypointCollectionClasses,
    keypointCollectionLabels,
    setActiveKeypointCollectionClass,
  ])

  const visibilityHotkeys = ['J', 'K', 'N', 'M']
  const visibilityOptions = keypointCollectionClasses?.map((keypointCollectionClass, index) => (
    <OptionContainer key={index}>
      <Checkbox checked={true} readOnly />({visibilityHotkeys[index]}) {keypointCollectionClass?.value} is Fully Visible
    </OptionContainer>
  ))

  const keypointClassOptions = useMemo(
    () =>
      activeKeypointCollectionClass?.keypointClasses?.map((keypointClass, index) => {
        const keypointIndex = keypointClass?.order - 1
        const keypoint = activeKeypoints?.find((keypoint) => keypoint?.keypointClass === keypointClass?.uuid)

        const setKeypointIsActive = (isActive) => {
          const newKeypoints = activeKeypoints?.map((keypoint) => ({ ...keypoint, isActive: false }))
          setKeypoints([
            ...newKeypoints?.slice(0, keypointIndex),
            { ...newKeypoints[keypointIndex], isActive },
            ...newKeypoints?.slice(keypointIndex + 1),
          ])
        }

        return (
          <KeypointOption
            key={index}
            keypointClass={keypointClass}
            keypoint={keypoint}
            setKeypointIsActive={setKeypointIsActive}
          />
        )
      }),
    [activeKeypointCollectionClass?.keypointClasses, activeKeypoints, setKeypoints],
  )

  return (
    <LabelsContainer>
      {showPhaser && (
        <FeatureContainer>
          <FeatureTitle>Phase</FeatureTitle>
          <FeatureOptionsContainer>{phaseLabelOptions}</FeatureOptionsContainer>
        </FeatureContainer>
      )}
      <FeatureContainer>
        <FeatureTitle>Keypoint Collections</FeatureTitle>
        <FeatureOptionsContainer>{keypointCollectionClassOptions}</FeatureOptionsContainer>
      </FeatureContainer>
      {/* <FeatureContainer>
        <FeatureTitle>Visibility</FeatureTitle>
        <FeatureOptionsContainer>{visibilityOptions}</FeatureOptionsContainer>
      </FeatureContainer> */}
      <FeatureContainer>
        <FeatureTitle>Keypoints</FeatureTitle>
        <FeatureOptionsContainer>{keypointClassOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
