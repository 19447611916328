import styled from 'styled-components'
import THEME from '../../../../constants/theme'

export const ActionsContainer = styled.div.attrs({
  className: 'ActionsContainer',
})`
  background: #192225;
  border-radius: 5px;
  width: 300px;
  min-width: 300px;
  display: flex;
`

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : 'transparent'};
    border: ${disabled ? 'none' : '1px solid #2A98E8'};;
    color: ${disabled ? '#999' : '#FFF'};
    border-radius: 5px;
    height: 32px;
    // width: 100px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      background: ${disabled ? 'none' : '#444'};
    }
    > a {
      all: unset;
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `,
)

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  display: flex;
  gap: 10px;
  background: #192225;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const StatsContainer = styled.div.attrs({
  className: 'StatsContainer',
})`
  background: #192225;
  border-radius: 5px;
  width: 300px;
  min-width: 300px;
  height: 100%;
  color: #ffffff;
  padding-top: 12px;
`

export const Loader = styled.div.attrs({
  className: 'Loader',
})`
  display: flex;
  height: 100px;
  width: 100%;
  gap: 10px;
  // top: 120px;
  // padding: 10px 0;
  // background: #192225;
  // height: 80px;
  // min-height: 80px;
  background: #192225;
  border-radius: 5px;
`

export const SubHeaderContainer = styled.div.attrs({
  className: 'SubHeaderContainer',
})`
  display: flex;
  width: 100%;
  gap: 10px;
  height: 90px;
  // top: 120px;
  // padding: 10px 0;
  // background: #192225;
  // height: 80px;
  // min-height: 80px;
`

export const ToolButton = styled.div.attrs({
  className: 'ToolButton',
})(
  ({ active, disabled, type }) => `
    background: ${
      !active || disabled
        ? 'gray'
        : type === 'identifier'
          ? THEME.identifier
          : type === 'phaser'
            ? THEME.variant6
            : type === 'keypointer'
              ? THEME.keypoints
              : THEME.segmentations
    };
    color: #ccc;
    border-radius: 5px;
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    &:hover {
      background: ${
        disabled
          ? 'gray'
          : active
            ? 'default'
            : type === 'identifier'
              ? THEME.identifierLight
              : type === 'phaser'
                ? THEME.variant6Light
                : type === 'keypointer'
                  ? THEME.keypointsLight
                  : THEME.segmentationsLight
      };
    }
  `,
)

export const ToolContainer = styled.div.attrs({
  className: 'ToolContainer',
})`
  display: flex;
  gap: 10px;
  background: transparent;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  justify-content: center;
`
