import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Header from './Header'
import Home from './Home'
import Login from './Login'
import Keypointer from './Kepointer'
import Collections from './Collections'
import Segmentor from './Segmentor'
import Studies from './Studies'

import { BodyContainer } from './ICardio.style'

const ICardio = () => {
  const user = useSelector((state) => state.auth.user)
  const userIsLoggedIn = !!user

  return (
    <BodyContainer>
      {userIsLoggedIn && <Header />}
      <Routes>
        <Route exact path="/login" Component={Login} />
        {userIsLoggedIn && (
          <>
            <Route exact path="/" Component={Home} />
            <Route exact path="/keypoints/*" Component={Keypointer} />
            <Route exact path="/segmentations/*" Component={Segmentor} />
            <Route exact path="/studies/*" Component={Studies} />
            <Route exact path="/collections/*" Component={Collections} />
          </>
        )}
        <Route path="*" element={<Navigate to={'/login'} />} />
      </Routes>
    </BodyContainer>
  )
}

export default ICardio
