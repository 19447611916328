import styled from 'styled-components'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : '#cc2020'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 32px;
    width: calc(50% - 5px);
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      opacity: 0.8
    }
    > a {
      all: unset;
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `,
)

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ControlsContainer = styled.div.attrs({
  className: 'ControlsContainer',
})`
  display: flex;
  flex-direction: column;
  width: 300px;
  border-radius: 5px;
  padding: 0px 10px;
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`

export const ToggleContainer = styled.div.attrs({
  className: 'ToggleContainer',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #aaa;
  }
`

export const ToggleSectionContainer = styled.div.attrs({
  className: 'ToggleSectionContainer',
})`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #192225;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
`
