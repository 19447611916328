import styled from 'styled-components'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : '#222'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 32px;
    // width: 100px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      background: ${disabled ? 'none' : '#444'};;
    }
    > a {
      all: unset;
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `,
)

export const FactContainer = styled.div.attrs({
  className: 'FactContainer',
})`
  display: flex;
  color: #ffffff;
  gap: 1em;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const FactTitle = styled.div.attrs({
  className: 'FactTitle',
})`
  width: 100%;
  border-radius: 5px 10px 0 0;
  color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  &:after {
    content: '';
    height: 10px;
    width: 80%;
    border-bottom: 1px solid gray;
  }
`

export const IconContainer = styled.div.attrs({
  className: 'IconContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  font-size: 20px;
  padding-left: 14px;
`

export const SideBar = styled.div.attrs({
  className: 'SideBar',
})`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  width: 300px;
  background: #192225;
  border-radius: 5px;
`

export const SubtitleContainer = styled.div.attrs({
  className: 'SubtitleContainer',
})`
  color: black;
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
  background: #192225;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  // height: 60px;
  padding: 10px;
`

export const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  color: black;
  height: 40px;
  padding: 0 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 250px;
  background: #eee;
`

export const TableContainer = styled.div.attrs({
  className: 'TableContainer',
})`
  display: flex;
  // flex-direction: column;
  gap: 1em;
  // padding: 0 20px;
  // height: 100%;
  width: 100%;
`

export const TableWrapper = styled.div.attrs({
  className: 'TableWrapper',
})`
  position: relative;
  width: 100%;
  // border-radius: 5px;
  overflow: hidden;
  > div > div > div > div {
    background: transparent !important;
  }
  .rdt_TableHeadRow {
    background-color: #192225 !important;
  }
  .rdt_Pagination {
    background-color: #192225 !important;
  }
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  // height: 60px;
  padding: 5px 0;
  text-align: center;
`
