import { memo } from 'react'

import {
  LabelsContainer,
  FeatureContainer,
  FeatureOptionsContainer,
  FeatureTitle,
  TitleContainer,
} from './Labels.style'

import PhaseOption from './PhaseOption'

const Labels = ({ activeFrame, phaseClasses, dicom, phaseLabels, setPhaseLabel }) => {
  const phaseLabelOptions = phaseClasses?.map((cardiacPhase, index) => {
    const phaseLabel = phaseLabels?.find((phaseLabelLabel) => phaseLabelLabel?.phaseClass === cardiacPhase?.value)
    return (
      <PhaseOption
        key={index}
        activeFrame={activeFrame}
        cardiacPhase={cardiacPhase}
        dicom={dicom}
        phaseLabel={phaseLabel}
        setPhaseLabel={setPhaseLabel}
      />
    )
  })

  return (
    <LabelsContainer>
      <TitleContainer>Set Phases</TitleContainer>
      <FeatureContainer>
        <FeatureTitle>Phase</FeatureTitle>
        <FeatureOptionsContainer>{phaseLabelOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
