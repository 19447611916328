import useAxios from 'axios-hooks'

import Loader from './Loader'
import { ANNOTATION_SETS } from '../../constants/api'
import { Card, CardsContainer, ContentContainer, HomeContainer, RowContainer } from './Home.style'
import SummaryCard from './SummaryCard'
import { Row, Col } from 'antd'

const Home = () => {
  const [{ data, loading }] = useAxios(
    {
      url: ANNOTATION_SETS,
    },
    {
      useCache: false,
    },
  )

  const loadingCards = Array.from({ length: 3 }).map((_, index) => (
    <Card key={index}>
      <Loader />
    </Card>
  ))

  const AnnotationSetCards = () => {
    return (
      <Row gutter={[16, 16]} justify="left" align="top" style={{ width: '100%' }}>
        {data?.results?.map((annotationSet) => (
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <SummaryCard annotationSet={annotationSet} key={annotationSet?.uuid} />
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <HomeContainer>
      <ContentContainer>
        <RowContainer>
          <div>My Open Jobs</div>
          <CardsContainer>{loading ? loadingCards : <AnnotationSetCards />}</CardsContainer>
        </RowContainer>
      </ContentContainer>
    </HomeContainer>
  )
}

export default Home
