import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa'

import {
  ButtonsContainer,
  QualifierContainer,
  QualityButton,
  Title,
  ViewsContainer,
  TitleContainer,
} from './Qualifier.style'

const Qualifier = ({ dicom, qualityLabel, setQualityLabel }) => {
  const goodQualityIsActive = qualityLabel?.qualityClass === 'GOOD'
  const badQualityIsActive = qualityLabel?.qualityClass === 'BAD'

  const handleQualityOnClick = (type) => {
    setQualityLabel({
      ...qualityLabel,
      dicom: dicom?.uuid,
      qualityClass: type,
    })
  }

  return (
    <QualifierContainer>
      <TitleContainer>Set Quality</TitleContainer>
      <ViewsContainer>
        <Title>Image Quality</Title>
        <ButtonsContainer>
          <QualityButton type="GOOD" $isActive={goodQualityIsActive} onClick={() => handleQualityOnClick('GOOD')}>
            <FaThumbsUp />
          </QualityButton>
          <QualityButton type="BAD" $isActive={badQualityIsActive} onClick={() => handleQualityOnClick('BAD')}>
            <FaThumbsDown />
          </QualityButton>
        </ButtonsContainer>
      </ViewsContainer>
    </QualifierContainer>
  )
}

export default Qualifier
