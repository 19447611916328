import styled from 'styled-components'
import THEME from '../../../../constants/theme'

export const ArrowIconContainer = styled.div.attrs({
  className: 'ArrowIconContainer',
})`
  display: flex;
  position: absolute;
  top: 50%;
  right: -6px;
  background: #aaa;
  border: 2px solid gray;
  border-radius: 10px;
  padding: 2px;
  width: 30px;
  height: 90px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 500;
  svg {
    fill: ${THEME.variant6};
  }
`

export const Box = styled.div.attrs({
  className: 'Box',
})(
  ({ type }) => `  
    background: ${
      type === 'identifier'
        ? THEME.identifier
        : type === 'phaser'
          ? THEME.variant6
          : type === 'keypointer'
            ? THEME.keypoints
            : type === 'segmentor'
              ? THEME.segmentations
              : 'green'
    };
    padding: 2x;
    height: 13px;
    width: 13px;
    border: 1px solid black;
  `,
)

export const BoxContainer = styled.div.attrs({
  className: 'BoxContainer',
})`
  display: flex;
  gap: 5px;
  align-items: center;
`

export const BoxesContainer = styled.div.attrs({
  className: 'BoxesContainer',
})`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const DescriptionContainer = styled.div.attrs({
  className: 'DescriptionContainer',
})(
  ({ isHidden }) => `
    display: ${isHidden ? 'none' : 'flex'};
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 13px 0;
  `,
)

export const DicomCardsContainer = styled.div.attrs({
  className: 'DicomCardsContainer',
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  gap: 10px;
`

export const DicomCardContainer = styled.div.attrs({
  className: 'DicomCardContainer',
})(
  ({ isActive, isLabeled }) => `
    width: 100%;
    > a {
      all: unset;
      padding: 0 15px 0 5px;
      display: flex;
      flex-direction: row;
      border-radius: 5px;
      // width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      cursor: pointer;
      background: ${isLabeled ? '#263237' : '#263237'};
      border: ${isActive ? '1px solid #2a98e8' : isLabeled ? '1px solid #4aa151' : '1px solid #263237'};
      color: #ffffff;
      &:hover {
        opacity: 0.9;
        border: '1px solid #2a98e8'!important;
      }
    }
  `,
)

export const DicomThumbnail = styled.img.attrs({
  className: 'DicomThumbnail',
})`
  height: 50px;
  width: 75px;
  border-radius: 5px;
`

export const DicomVideoThumbnail = styled.video.attrs({
  className: 'DicomVideoThumbnail',
})`
  height: 50px;
  width: 75px;
  border-radius: 5px;
`

export const DicomsContainer = styled.div.attrs({
  className: 'DicomsContainer',
})`
  // position: relative;
`

export const EmptyBox = styled.div.attrs({
  className: 'EmptyBox',
})`
  content: '-';
  padding: 2x;
  height: 13px;
  width: 13px;
  text-align: center;
`
