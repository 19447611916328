import { memo, useMemo } from 'react'

import FeatureOption from './FeatureOption'
import {
  LabelsContainer,
  FeatureContainer,
  FeatureOptionsContainer,
  FeatureTitle,
  Checkbox,
  OptionContainer,
} from './Labels.style'
import PhaseOption from './PhaseOption'

const Labels = ({
  segmentationClasses,
  activeSegmentationClass,
  activeFrame,
  dicom,
  phaseLabels,
  segmentations,
  setActiveFrameIndex,
  setActiveSegmentationClass,
  setSegmentation,
}) => {
  const usablePhases = phaseLabels?.filter((phaseLabel) => phaseLabel?.phaseClass !== 'Mid-Systole')
  const phaseLabelOptions = usablePhases?.map((phaseLabel, index) => {
    const isActive = activeFrame?.uuid === phaseLabel?.frame
    const segmentationClassUuids = segmentationClasses?.map((segmentationClass) => segmentationClass?.uuid)
    const labeledSegmentations = segmentations?.filter(
      (segmentation) =>
        segmentation?.frame === phaseLabel?.frame &&
        segmentationClassUuids?.includes(segmentation?.segmentationClass) &&
        (!!segmentation?.paths?.length || !segmentation?.isFullyVisible),
    )
    const isLabeled = labeledSegmentations?.length === segmentationClasses?.length
    const handlePhaseOptionOnClick = () => setActiveFrameIndex(phaseLabel?.frameIndex)

    return (
      <PhaseOption
        key={index}
        phaseLabel={phaseLabel}
        isActive={isActive}
        isLabeled={isLabeled}
        handlePhaseOptionOnClick={handlePhaseOptionOnClick}
      />
    )
  })

  const segmentationClassOptions = useMemo(
    () =>
      segmentationClasses?.map((segmentationClass, index) => {
        const segmentation = segmentations?.find(
          (segmentation) =>
            segmentation?.segmentationClass === segmentationClass?.uuid && segmentation?.frame === activeFrame?.uuid,
        )
        const isActive = activeSegmentationClass?.uuid === segmentationClass?.uuid
        const isLabeled = !!segmentation?.paths?.length || segmentation?.isFullyVisible === false

        const setActive = () => setActiveSegmentationClass(segmentationClass)
        return (
          <FeatureOption
            key={segmentationClass?.uuid}
            segmentationClass={segmentationClass?.value}
            hotkey={`${index + 1}`}
            isActive={isActive}
            isLabeled={isLabeled}
            setActive={setActive}
          />
        )
      }),
    [activeSegmentationClass?.uuid, activeFrame?.uuid, segmentationClasses, segmentations, setActiveSegmentationClass],
  )

  const visibilityHotkeys = ['J', 'K', 'N', 'M']
  const visibilityOptions = segmentationClasses?.map((segmentationClass, index) => {
    const segmentationLabel = segmentations?.find(
      (segmentation) =>
        segmentation?.segmentationClass === segmentationClass?.uuid && segmentation?.frame === activeFrame?.uuid,
    )

    const isFullyVisible = segmentationLabel ? segmentationLabel?.isFullyVisible : true
    const handleVisibilityOptionOnClick = () => {
      setSegmentation({
        ...segmentationLabel,
        dicom: dicom?.uuid,
        segmentationClass: segmentationClass?.uuid,
        frame: activeFrame?.uuid,
        frameIndex: activeFrame?.index,
        isFullyVisible: !isFullyVisible,
      })
    }

    return (
      <OptionContainer key={index} onClick={handleVisibilityOptionOnClick}>
        <Checkbox checked={isFullyVisible} readOnly />({visibilityHotkeys[index]}) {segmentationClass?.value} is Fully
        Visible
      </OptionContainer>
    )
  })

  return (
    <LabelsContainer>
      <FeatureContainer>
        <FeatureTitle>Phase</FeatureTitle>
        <FeatureOptionsContainer>{phaseLabelOptions}</FeatureOptionsContainer>
      </FeatureContainer>
      <FeatureContainer>
        <FeatureTitle>Features</FeatureTitle>
        <FeatureOptionsContainer>{segmentationClassOptions}</FeatureOptionsContainer>
      </FeatureContainer>
      <FeatureContainer>
        <FeatureTitle>Visibility</FeatureTitle>
        <FeatureOptionsContainer>{visibilityOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
