import Actions from './Actions'
import Summary from './Summary'
import {
  ActionsContainer,
  Button,
  ButtonsContainer,
  Loader,
  StatsContainer,
  SubHeaderContainer,
  ToolButton,
  ToolContainer,
} from './SubHeader.style'

const SubHeader = ({
  study,
  dicoms,
  checkIfDicomIsLabeled,
  previousButtonIsDisabled,
  nextButtonIsDisabled,
  identifierIsDisabled,
  identifierIsActive,
  setActiveTool,
  showPhaser,
  phaserIsDisabled,
  phaserIsActive,
  showKeypointer,
  keypointerIsDisabled,
  keypointerIsActive,
  showSegmentor,
  segmentorIsDisabled,
  segmentorIsActive,
  dicom,
  frames,
  activePhaseLabels,
  keypointCollectionLabels,
  segmentations,
  activeViewLabel,
  activeQualityLabel,
  setDataIsSaving,
  setErrorOccured,
  setViewLabelChangesAreSaved,
  setQualityLabelChangesAreSaved,
  setPhaseLabelLabelChangesAreSaved,
  setKeypointCollectionLabelLabelsChangesAreSaved,
  setSegmentationChangesAreSaved,
  setDicomJobChangesAreSaved,
  showPageLoader,
  dicomLabelingJob,
}) => {
  return (
    <SubHeaderContainer>
      <StatsContainer>
        <Summary study={study} dicoms={dicoms} checkIfDicomIsLabeled={checkIfDicomIsLabeled} />
      </StatsContainer>
      <ButtonsContainer>
        <Button disabled={previousButtonIsDisabled}>(R) PREVIOUS</Button>
        <ToolContainer>
          <ToolButton
            disabled={identifierIsDisabled}
            active={identifierIsActive}
            type="identifier"
            onClick={() => setActiveTool('identifier')}
          >
            Identifier
          </ToolButton>
          {showPhaser && (
            <ToolButton
              disabled={phaserIsDisabled}
              active={phaserIsActive}
              type="phaser"
              onClick={() => setActiveTool('phaser')}
            >
              Phaser
            </ToolButton>
          )}
          {showKeypointer && (
            <ToolButton
              disabled={keypointerIsDisabled}
              active={keypointerIsActive}
              type="keypointer"
              onClick={() => setActiveTool('keypointer')}
            >
              Keypointer
            </ToolButton>
          )}
          {showSegmentor && (
            <ToolButton
              disabled={segmentorIsDisabled}
              active={segmentorIsActive}
              type="segmentor"
              onClick={() => setActiveTool('segmentor')}
            >
              Segmentor
            </ToolButton>
          )}
        </ToolContainer>
        <Button disabled={nextButtonIsDisabled}>(Y) NEXT</Button>
      </ButtonsContainer>
      <ActionsContainer>
        <Actions
          dicom={dicom}
          frames={frames}
          phaseLabels={activePhaseLabels}
          keypointCollectionLabels={keypointCollectionLabels}
          segmentations={segmentations}
          viewLabel={activeViewLabel}
          qualityLabel={activeQualityLabel}
          setDataIsSaving={setDataIsSaving}
          setErrorOccured={setErrorOccured}
          setViewLabelChangesAreSaved={setViewLabelChangesAreSaved}
          setQualityLabelChangesAreSaved={setQualityLabelChangesAreSaved}
          setPhaseLabelLabelChangesAreSaved={setPhaseLabelLabelChangesAreSaved}
          setKeypointCollectionLabelLabelsChangesAreSaved={setKeypointCollectionLabelLabelsChangesAreSaved}
          setSegmentationChangesAreSaved={setSegmentationChangesAreSaved}
          setDicomJobChangesAreSaved={setDicomJobChangesAreSaved}
          checkIfDicomIsLabeled={checkIfDicomIsLabeled}
          dicomLabelingJob={dicomLabelingJob}
        />
      </ActionsContainer>
    </SubHeaderContainer>
  )
}

export default SubHeader
