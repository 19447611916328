import { useCallback, useEffect, useMemo, useState } from 'react'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'

import { ANNOTATION_SETS, KEYPOINT_COLLECTION_LABELS, KEYPOINT_JOBS } from '../../../constants/api'
import Actions from './Actions'
import Canvas from './Canvas'
import Controls from './Controls'
import Details from './Details'
import Labels from './Labels'
import Loader from './Loader'
import {
  Button,
  CanvasContainer,
  ContentContainer,
  HeaderContainer,
  SideBarContainer,
  StatusTag,
  ViewerContainer,
} from './Viewer.style'

const Viewer = () => {
  const { datasetId, jobId } = useParams()

  const datasetUrl = useMemo(() => `${ANNOTATION_SETS}${datasetId}/`, [datasetId])
  const keypointUrl = useMemo(() => `${KEYPOINT_JOBS}${jobId}/`, [jobId])

  const [dataIsLoading, setDataIsLoading] = useState(true)
  const [{ data: datasetResponse, loading: datasetResponseIsLoading }] = useAxios(datasetUrl, {
    useCache: false,
  })
  const [{ data: keypointJobResponse, loading: keypointJobResponseIsLoading }] = useAxios(keypointUrl, {
    useCache: false,
  })

  const [dataset, setDataset] = useState()
  const [keypointJob, setKeypointJob] = useState()
  const [activeFrameIndex, setActiveFrameIndex] = useState(0)
  const [frames, setFrames] = useState([])
  const [keypointCollectionLabels, setKeypointCollectionLabelLabels] = useState([])
  const [videoState, setVideoState] = useState(false)
  const [intervalId, setIntervalId] = useState()

  const [nextOnSave, setNextOnSave] = useState(true)
  const [showMeasurements, setShowMeasurements] = useState(true)

  const keypointCollectionLabelsParams = useMemo(
    () => ({
      frame__media__dicom__uuid: keypointJob?.dicom?.uuid,
      key_point_collection_class__uuid: dataset?.keypointCollectionClass?.uuid,
      paginate: false,
    }),
    [keypointJob?.dicom?.uuid, dataset?.keypointCollectionClass?.uuid],
  )

  const [{ data: keypointCollectionLabelsResponse }, getKeypointCollections] = useAxios(
    {
      url: KEYPOINT_COLLECTION_LABELS,
      params: keypointCollectionLabelsParams,
    },
    {
      manual: true,
      useCache: false,
      autoCancel: false,
    },
  )
  const [{ loading: keypointCollectionLabelsUpsertIsLoading }, upsertKeypointCollections] = useAxios(
    KEYPOINT_COLLECTION_LABELS,
    {
      manual: true,
      autoCancel: false,
    },
  )
  const [{ loading: keypointJobUpdateIsLoading }, updateKeypointJob] = useAxios(KEYPOINT_JOBS, {
    manual: true,
    autoCancel: false,
  })

  const dicom = keypointJob?.dicom
  const numberOfKeypoints = dataset?.numberOfJobs
  const numberOfFrames = dicom?.frames?.length
  const numberOfSeconds = 1000 / dicom?.framesPerSecond

  const activeFrame = useMemo(() => frames[activeFrameIndex], [frames, activeFrameIndex])
  const systolicFrame = useMemo(() => frames?.find((frame) => frame.isSystolic) || {}, [frames])
  const diastolicFrame = useMemo(() => frames?.find((frame) => frame.isDiastolic) || {}, [frames])
  const activeKeypoints = useMemo(() => activeFrame?.keypointLabels, [activeFrame])

  const setSystolicFrame = useCallback(
    (isSelected) => {
      setFrames((frames) => {
        const newFrames = frames?.map((frame) => ({ ...frame, isSystolic: false }))
        return [
          ...newFrames.slice(0, activeFrameIndex),
          { ...newFrames[activeFrameIndex], isSystolic: isSelected, isDiastolic: false },
          ...newFrames.slice(activeFrameIndex + 1),
        ]
      })
    },
    [activeFrameIndex],
  )

  const setDiastolicFrame = useCallback(
    (isSelected) => {
      setFrames((frames) => {
        const newFrames = frames?.map((frame) => ({ ...frame, isDiastolic: false }))
        return [
          ...newFrames.slice(0, activeFrameIndex),
          { ...newFrames[activeFrameIndex], isDiastolic: isSelected, isSystolic: false },
          ...newFrames.slice(activeFrameIndex + 1),
        ]
      })
    },
    [activeFrameIndex],
  )

  const setKeypoints = useCallback(
    (keypointLabels) => {
      setFrames((frames) => {
        return [
          ...frames?.slice(0, activeFrameIndex),
          { ...frames[activeFrameIndex], keypointLabels },
          ...frames?.slice(activeFrameIndex + 1),
        ]
      })
    },
    [activeFrameIndex],
  )

  const playVideo = useCallback(
    (speed) => {
      setVideoState(speed)
      const fps = numberOfSeconds * speed
      const id = setInterval(() => {
        setActiveFrameIndex((activeFrameIndex) => {
          if (activeFrameIndex === numberOfFrames - 1) return 0
          return activeFrameIndex + 1
        })
      }, fps)
      setIntervalId(id)
    },
    [numberOfFrames, numberOfSeconds],
  )

  const pauseVideo = useCallback(() => {
    if (intervalId !== null) {
      setVideoState(0)
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [intervalId])

  useEffect(() => {
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [intervalId])

  useEffect(() => {
    if (!frames?.length) return
    getKeypointCollections()
  }, [frames?.length, getKeypointCollections])

  useEffect(() => {
    if (!keypointCollectionLabelsResponse) return
    setKeypointCollectionLabelLabels(keypointCollectionLabelsResponse)
  }, [keypointCollectionLabelsResponse])

  useEffect(() => {
    if (!datasetResponse || !keypointJobResponse) return
    setDataset(datasetResponse)
    setKeypointJob(keypointJobResponse)
  }, [datasetResponse, keypointJobResponse])

  useEffect(() => {
    if (!dataset || !dicom) return

    const setKeypointsData = (labeledCollection) => {
      const keypointLabels = dataset?.keypointCollectionClass?.keypointClasses?.map((keypointClass) => {
        const labeledKeypoint = labeledCollection?.keypointLabels?.find(
          (keypoint) => keypoint?.keypointClass === keypointClass.value,
        )
        const isLabeled = !!labeledKeypoint
        const x = labeledKeypoint?.x
        const y = labeledKeypoint?.y

        return {
          keypointClass,
          x,
          y,
          isActive: false,
          isLabeled,
        }
      })

      const labeledKeypoints = keypointLabels?.filter((keypoint) => keypoint?.isLabeled)
      const nextKeypointIndex = labeledKeypoints?.length
      const nextKeypoint = keypointLabels[nextKeypointIndex]
      const newKeypoints = [
        ...labeledKeypoints,
        {
          ...nextKeypoint,
          isActive: true,
        },
        ...keypointLabels?.slice(nextKeypointIndex + 1),
      ]?.slice(0, dataset?.keypointCollectionClass?.keypointClasses?.length)

      return newKeypoints
    }

    const framesData = dicom?.frames?.map((frame) => {
      const labeledCollection = keypointCollectionLabels?.find(
        (keypointCollectionLabel) => keypointCollectionLabel?.frame === frame.uuid,
      )
      const isSystolic = labeledCollection?.phaseClass === 'Systolic'
      const isDiastolic = labeledCollection?.phaseClass === 'Diastolic'

      return {
        ...frame,
        keypointCollectionClass: dataset?.keypointCollectionClass?.uuid,
        isSystolic,
        isDiastolic,
        keypointLabels: setKeypointsData(labeledCollection),
      }
    })

    setFrames(framesData)
  }, [dataset, dicom, keypointCollectionLabels])

  // wait 1 second for all components to mount
  useEffect(() => {
    // Function to check if any API is currently loading
    const checkIfApisAreLoading = () =>
      datasetResponseIsLoading ||
      keypointJobResponseIsLoading ||
      keypointCollectionLabelsUpsertIsLoading ||
      keypointJobUpdateIsLoading

    // Initial check if APIs are loading
    if (checkIfApisAreLoading()) {
      setDataIsLoading(true)

      // Promise that resolves when all API calls are completed
      const apisLoaded = new Promise((resolve) => {
        const checkApis = () => {
          if (!checkIfApisAreLoading()) {
            resolve()
          } else {
            setTimeout(checkApis, 1000) // Check every 1 second
          }
        }
        checkApis()
      })

      // Promise that resolves after at least 1 second
      const oneSecondWait = new Promise((resolve) => {
        setTimeout(resolve, 1000)
      })

      // Use Promise.all to wait for both promises to resolve
      Promise.all([apisLoaded, oneSecondWait]).then(() => {
        setDataIsLoading(false)
      })
    } else {
      // If APIs are not loading, wait for at least 1 second before setting loading to false
      setTimeout(() => setDataIsLoading(false), 1000)
    }
  }, [
    datasetResponseIsLoading,
    keypointJobResponseIsLoading,
    keypointCollectionLabelsUpsertIsLoading,
    keypointJobUpdateIsLoading,
    setDataIsLoading,
  ])

  return (
    <ViewerContainer>
      <HeaderContainer>
        <Button>
          <a href={`/keypoints/${datasetId}`}>
            <IoMdArrowRoundBack />
            <span>BACK TO DATASET</span>
          </a>
        </Button>
        <StatusTag isHidden={dataIsLoading} isLabeled={keypointJob?.labeled} isSkipped={keypointJob?.skipped}>
          {keypointJob?.labeled ? 'LABELED' : keypointJob?.skipped ? 'SKIPPED' : ''}
        </StatusTag>
      </HeaderContainer>
      <ContentContainer>
        <SideBarContainer isHidden={!dataIsLoading}>
          <Loader />
        </SideBarContainer>
        <SideBarContainer isHidden={dataIsLoading}>
          <Details
            dataset={dataset}
            dicom={dicom}
            systolicFrame={systolicFrame}
            diastolicFrame={diastolicFrame}
            isLoading={dataIsLoading}
          />
          <Labels
            activeFrame={activeFrame}
            systolicFrame={systolicFrame}
            diastolicFrame={diastolicFrame}
            activeKeypoints={activeKeypoints}
            setSystolicFrame={setSystolicFrame}
            setDiastolicFrame={setDiastolicFrame}
            setKeypoints={setKeypoints}
          />
          <Controls
            nextOnSave={nextOnSave}
            setNextOnSave={setNextOnSave}
            showMeasurements={showMeasurements}
            setShowMeasurements={setShowMeasurements}
          />
          <Actions
            dataIsLoading={dataIsLoading}
            dataset={dataset}
            diastolicFrame={diastolicFrame}
            frames={frames}
            keypointJob={keypointJob}
            nextOnSave={nextOnSave}
            systolicFrame={systolicFrame}
            setActiveFrameIndex={setActiveFrameIndex}
            setKeypointCollectionLabelLabels={setKeypointCollectionLabelLabels}
            setKeypointJob={setKeypointJob}
            updateKeypointJob={updateKeypointJob}
            upsertKeypointCollections={upsertKeypointCollections}
            pauseVideo={pauseVideo}
          />
        </SideBarContainer>
        <CanvasContainer isHidden={!dataIsLoading}>
          <Loader />
        </CanvasContainer>
        <CanvasContainer isHidden={dataIsLoading}>
          <Canvas
            keypointJob={keypointJob}
            numberOfKeypoints={numberOfKeypoints}
            dicom={dicom}
            datasetId={datasetId}
            frames={frames}
            playVideo={playVideo}
            pauseVideo={pauseVideo}
            videoState={videoState}
            activeFrame={activeFrame}
            activeKeypoints={activeKeypoints}
            systolicFrame={systolicFrame}
            diastolicFrame={diastolicFrame}
            setFrames={setFrames}
            setActiveFrame={setActiveFrameIndex}
            setKeypoints={setKeypoints}
            showMeasurements={showMeasurements}
          />
        </CanvasContainer>
      </ContentContainer>
    </ViewerContainer>
  )
}

export default Viewer
