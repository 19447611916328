import JobChart from './JobChart'
import { CardContent, CardTitle, SummaryCardContainer, TypeTag } from './SummaryCard.style'

const SummaryCard = ({ annotationSet }) => {
  const url =
    annotationSet?.type === 'KEYPOINTS'
      ? `keypoints/${annotationSet?.uuid}`
      : annotationSet?.type === 'SEGMENTATION'
        ? `segmentations/${annotationSet?.uuid}`
        : annotationSet?.type === 'FULL_STUDY'
          ? `studies/${annotationSet?.uuid}`
          : `studies/${annotationSet?.uuid}`

  return (
    <SummaryCardContainer href={url} key={annotationSet?.uuid}>
      <CardTitle type={annotationSet?.type}>
        {annotationSet?.name === '' || annotationSet?.name == null ? 'No Provided Name' : annotationSet?.name}
        <TypeTag type={annotationSet?.type}>{annotationSet?.type}</TypeTag>
      </CardTitle>

      <CardContent>
        <JobChart annotationSet={annotationSet} />
      </CardContent>
    </SummaryCardContainer>
  )
}

export default SummaryCard
