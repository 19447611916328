import { useHotkeys } from 'react-hotkeys-hook'

import { Checkbox, PhaseOptionContainer } from './PhaseOption.style'

const PhaseOption = ({ activeFrame, cardiacPhase, dicom, phaseLabel, setPhaseLabel }) => {
  const isSelected = ![null, undefined].includes(phaseLabel?.frame)
  const hotkey = cardiacPhase?.value?.[0]
  const label = cardiacPhase?.value

  const handlePhaseOptionOnClick = () => {
    const newPhase =
      phaseLabel?.phaseClass === cardiacPhase?.value && phaseLabel?.frame === activeFrame?.uuid
        ? null
        : cardiacPhase?.value

    setPhaseLabel({
      ...phaseLabel,
      frame: activeFrame?.uuid,
      frameIndex: activeFrame?.index,
      phaseClass: newPhase,
      dicom: dicom?.uuid,
    })
  }

  useHotkeys(hotkey, (event) => {
    event?.preventDefault()
    handlePhaseOptionOnClick()
  })

  return (
    <PhaseOptionContainer $isSelected={isSelected} onClick={handlePhaseOptionOnClick}>
      <Checkbox checked={isSelected} readOnly />({hotkey}) {label}
    </PhaseOptionContainer>
  )
}

export default PhaseOption
