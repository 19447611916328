import styled from 'styled-components'

export const FactContainer = styled.div.attrs({
  className: 'FactContainer',
})`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  > div {
    display: flex;
    font-size: 15px;
  }
`

export const JobChartContainer = styled.div.attrs({
  className: 'JobChartContainer',
})`
  // background: #192225;
  padding: 0 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const Percent = styled.div.attrs({
  className: 'Percent',
})`
  width: 9ch;
  text-align: right;
`

export const Tag = styled.div.attrs({
  className: 'Tag',
})(
  ({ backgroundColor }) => `
    background: ${backgroundColor};
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 12px !important;
    margin-right: 1ch;
    width: 6ch;
  `,
)
