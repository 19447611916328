import { ContentContainer, DetailsContainer, FactContainter, FactLabel, TitleContainer } from './Details.style'

const Details = ({ dataset, dicom, systolicFrame, diastolicFrame }) => {
  const hasSystolicFrame = typeof systolicFrame?.index === 'number'
  const hasDiastolicFrame = typeof diastolicFrame?.index === 'number'
  const systolicFrameIndex = hasSystolicFrame ? systolicFrame?.index + 1 : 'not set'
  const diastolicFrameIndex = hasDiastolicFrame ? diastolicFrame?.index + 1 : 'not set'
  const totalKeypoints = dataset?.keypointCollectionClass?.keypointClasses?.length
  const systolicKeypointCount = systolicFrame?.keypointLabels?.filter((keypoint) => keypoint?.isLabeled)?.length || 0
  const diastolicKeypointCount = diastolicFrame?.keypointLabels?.filter((keypoint) => keypoint?.isLabeled)?.length || 0

  return (
    <DetailsContainer>
      {/* <TitleContainer>Details</TitleContainer> */}
      <ContentContainer>
        {/* <FactContainter>
          <div>Number of Frames: </div>
          <FactLabel>{dicom?.frames?.length}</FactLabel>
        </FactContainter>
        <FactContainter>
          <div>FPS</div>
          <FactLabel> {dicom?.framesPerSecond?.toFixed(2)}</FactLabel>
        </FactContainter> */}
        <FactContainter>
          <div>Systolic Frame: </div>
          <FactLabel isSystolic isLabeled={hasSystolicFrame}>
            {systolicFrameIndex}
          </FactLabel>
        </FactContainter>
        <FactContainter>
          <div>Systolic Keypoints: </div>
          <FactLabel isSystolic isLabeled={systolicKeypointCount === totalKeypoints}>
            {systolicKeypointCount}/{totalKeypoints}
          </FactLabel>
        </FactContainter>
        <FactContainter>
          <div>Diastolic Frame: </div>
          <FactLabel isDiastolic isLabeled={hasDiastolicFrame}>
            {diastolicFrameIndex}
          </FactLabel>
        </FactContainter>
        <FactContainter>
          <div>Diastolic Keypoints: </div>
          <FactLabel isDiastolic isLabeled={diastolicKeypointCount === totalKeypoints}>
            {diastolicKeypointCount}/{totalKeypoints}
          </FactLabel>
        </FactContainter>
      </ContentContainer>
    </DetailsContainer>
  )
}

export default Details
