import styled from 'styled-components'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled, isActive }) => `
    background: ${disabled ? 'none' : isActive ? '#222' : '#444'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 30px;
    // width: 100px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    margin-bottom: 2px;
    &:hover {
      opacity: ${disabled ? 1 : 0.9};
    }
    > a {
      all: unset;
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `,
)

export const ButtonsContainer = styled.div.attrs({
  className: 'ButtonsContainer',
})`
  overflow: auto;
  max-height: 100%;
`

export const DicomsContainer = styled.div.attrs({
  className: 'DicomsContainer',
})`
  width: 100%;
  background: #192225;
  padding: 10px;
  border-radius: 5px;
`
