import styled from 'styled-components'

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled }) => `
    background: ${disabled ? 'none' : '#2a98e9'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      opacity: 0.8
    }
    > svg {
      font-size: 15px;
    }
  `,
)

export const ControlsContainer = styled.div.attrs({
  className: 'ControlsContainer',
})`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const HotkeyContainer = styled.div.attrs({
  className: 'HotkeyContainer',
})`
  margin-right: 5px;
`
