import styled from 'styled-components'

export const DrawContainer = styled.div.attrs({
  className: 'DrawContainer',
  id: 'FramesWrapper',
})(
  ({ scale }) => `
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transform: scale(${scale});
    transition: transform 0.2s ease-in-out;
  `,
)

export const DrawSection = styled.canvas.attrs({
  className: 'DrawSection',
  id: 'Draw',
})`
  width: 100% !important;
  height: 100% !important;
`

export const WrapDrawControl = styled.div.attrs({
  className: 'WrapDrawControl',
})`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
`

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled, active }) => `
    background: ${disabled ? 'none' : active ? '#3498db' : '#222'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 32px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    margin-bottom: 5px;
    &:hover {
      background: ${disabled ? 'none' : '#444'};;
    }
  
  `,
)
