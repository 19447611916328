import { FactContainer, SummaryContainer } from './Summary.style'

const Summary = ({ study, dicoms, checkIfDicomIsLabeled }) => {
  const labeledDicoms = dicoms?.filter((dicom) => {
    const { dicomIsLabeled } = checkIfDicomIsLabeled(dicom)
    return dicomIsLabeled
  })

  return (
    <SummaryContainer>
      <FactContainer>
        <div>Number of dicoms:</div>
        <div>
          {dicoms?.length} of {study?.numberOfDicomLabelingJobs}
        </div>
      </FactContainer>
      <FactContainer>
        <div>Missing from study:</div>
        <div>
          {study?.numberOfDicomLabelingJobs - dicoms?.length} of {study?.numberOfDicomLabelingJobs}
        </div>
      </FactContainer>
      <FactContainer>
        <div>Completed dicoms:</div>
        <div>
          {study?.numberOfCompletedDicomLabelingJobs} of {dicoms?.length}
        </div>
      </FactContainer>
    </SummaryContainer>
  )
}

export default Summary
