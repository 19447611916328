import styled from 'styled-components'

export const ButtonWrapper = styled.div.attrs({
  className: 'ButtonWrapper',
})`
  display: inline-block;
  background: transparent;
  button {
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
  }

  button {
    // opacity: ${(props) => (props.loading ? '0.25' : ' 1')};
    pointer-events: ${(props) => (props.loading ? 'none' : '')};
  }
  
  &.default {
    button {
      background-color: #32444d;
      border-radius: 12px;
      color: #f1f4f6;
      height: 40px;
      text-transform: capitalize;
      width: 100%;
      border-color: #32444d;
      &:hover {
        text-decoration: none;
        background-color: rgba(3, 81, 104, 0.08);
        border-color: rgba(3, 81, 104, 0.08);
      }
    }
  }
  &.primary {
    border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    height: 45px;
    padding: 2px;
    & > button {
      background-image: linear-gradient(222deg, #00d4d4, #0f87ff);
      border-radius: 6px;
      color: #fff;
      margin-left: 0;
      height: 100%;
    }
  }
  &.bordered {
    & > button {
      border-color: #06bce1 #00d4d4 #06bce1 #09a4ed;
      border-radius: 10px;
      border-style: solid;
      border-width: 2px;
      color: #fff;
      font-size: 14px;
      height: 45px;
      padding: 10px;
      text-transform: capitalize;
      &:hover {
        background-color: #123;
      }
    }
  }
`

export const ButtonStyled = styled.button.attrs({})`
  width: 100%;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(3, 81, 104, 0.5);
  color: rgb(3, 81, 104);
  // width: ${(props) => (props.fullwidth ? '100%' : '')};
`
