import { JobChartContainer, Percent } from './JobChart.style'
import THEME from '../../../../constants/theme'

import { Flex, Progress, Tooltip } from 'antd'

const ProgressBar = ({ label, percentage, number, color }) => {
  return (
    <Flex horizontal alignItems="center" justifyContent="space-between" gap={24} style={{ alignItems: 'center' }}>
      <Flex justify={'space-between'} gap={6} style={{ minWidth: 100 }}>
        <div>{label}</div>
        <div>{number}</div>
      </Flex>
      <Tooltip title={`${label} ${percentage}%`}>
        <Progress
          percent={!isNaN(percentage) ? percentage : 0}
          strokeColor={color}
          trailColor={'gray'}
          showInfo={false}
        />
      </Tooltip>
      <Percent>{!isNaN(percentage) ? percentage : 0}%</Percent>
    </Flex>
  )
}

const JobChart = ({ annotationSet }) => {
  const selectedJobs = annotationSet?.numberOfStudyLabelingJobs
  const labeledJobs = annotationSet?.numberOfCompletedStudyLabelingJobs
  const skippedJobs = annotationSet?.numberOfSkippedStudyLabelingJobs
  const remainingJobs = selectedJobs - labeledJobs - skippedJobs

  const labeledPercent = ((labeledJobs / selectedJobs) * 100).toFixed(0)
  const skippedPercent = ((skippedJobs / selectedJobs) * 100).toFixed(0)
  const remainingPercent = ((remainingJobs / selectedJobs) * 100).toFixed(0)
  const labeledBackgroundColor = annotationSet?.type === 'KEYPOINTS' ? THEME.keypointLabels : THEME.segmentations
  const skippedBackgroundColor = THEME.identifier
  const remainingBackgroundColor = THEME.variant6Light

  return (
    <JobChartContainer>
      <div>
        <ProgressBar
          label={'Labeled'}
          percentage={!!labeledJobs ? labeledPercent : 0}
          color={labeledBackgroundColor}
          number={labeledJobs}
        />

        <ProgressBar
          label={'Skipped'}
          percentage={!!skippedJobs ? skippedPercent : 0}
          color={skippedBackgroundColor}
          number={skippedJobs}
        />

        <ProgressBar
          label={'Remaining'}
          percentage={remainingPercent}
          color={remainingBackgroundColor}
          number={remainingJobs}
        />

        <Flex justify={'space-between'}>
          <div>Total</div>
          {selectedJobs}
        </Flex>
      </div>
    </JobChartContainer>
  )
}

export default JobChart
