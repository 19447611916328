import { API_ICARDIO } from './env.const'

export const ANNOTATION_SETS = `${API_ICARDIO}/api/v1/full-study-annotation-sets/`
export const AUTHORIZATION = `${API_ICARDIO}/api/v1/auth/login/`
export const DICOMS = `${API_ICARDIO}/api/v1/dicoms/`
export const DICOM_LABELING_JOBS = `${API_ICARDIO}/api/v1/dicom-labeling-jobs/`
export const FRAMES = `${API_ICARDIO}/api/v1/frames/`
export const PHASE_CLASSES = `${API_ICARDIO}/api/v1/phase-classes/`
export const PHASE_LABELS = `${API_ICARDIO}/api/v1/phase-labels/`
export const KEYPOINT_COLLECTION_CLASSES = `${API_ICARDIO}/api/v1/keypoint-collection-classes/`
export const KEYPOINT_COLLECTION_LABELS = `${API_ICARDIO}/api/v1/keypoint-collection-labels/`
export const KEYPOINT_JOBS = `${API_ICARDIO}/api/v1/keypoint-jobs/`
export const SEGMENTATION_CLASSES = `${API_ICARDIO}/api/v1/segmentation-classes/`
export const SEGMENTATION_JOBS = `${API_ICARDIO}/api/v1/segmentation-jobs/`
export const SEGMENTATION_LABELS = `${API_ICARDIO}/api/v1/segmentation-labels/`
export const STUDY_COLLECTIONS = `${API_ICARDIO}/api/v1/study-collections/`
export const STUDY_LABELING_JOBS = `${API_ICARDIO}/api/v1/study-labeling-jobs/`
export const VIEW_CLASSES = `${API_ICARDIO}/api/v1/view-classes/`
export const VIEW_LABELS = `${API_ICARDIO}/api/v1/view-labels/`
export const QUALITY_LABELS = `${API_ICARDIO}/api/v1/quality-labels/`
