import styled from 'styled-components'

export const AuditorContainer = styled.div.attrs({
  className: 'AuditorContainer',
})`
  background: black;
  height: 100%;
  width: 100%;
`

export const Button = styled.div.attrs({
  className: 'Button',
})(
  ({ disabled, isActive }) => `
    background: ${disabled ? 'none' : isActive ? '#222' : '#444'};
    color: ${disabled ? '#999' : '#ccc'};
    border-radius: 5px;
    height: 32px;
    // width: 100px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    &:hover {
      opacity: ${disabled ? 1 : 0.9};
    }
    > a {
      all: unset;
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  `,
)

export const ColumnContainer = styled.div.attrs({
  className: 'ColumnContainer',
})`
  background: #192225;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  // gap: 5px;
  // flex: 1 0;
  min-width: 300px;
  height: fit-content;
`

export const ColumnTitle = styled.div.attrs({
  className: 'ColumnTitle',
})`
  width: 100%;
  color: #ccc;
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  &:after {
    content: '';
    height: 10px;
    width: 80%;
    border-bottom: 1px solid #aaa;
  }
`

export const ContentContainer = styled.div.attrs({
  className: 'ContentContainer',
})`
  height: calc(100% - 60px);
  padding: 15px;
  display: flex;
  gap: 15px;
`

export const HeaderContainer = styled.div.attrs({
  className: 'HeaderContainer',
})`
  width: 100%;
  background: #192225;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
`

export const FactContainer = styled.div.attrs({
  className: 'FactContainer',
})`
  display: flex;
  gap: 1em;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const FactTitle = styled.div.attrs({
  className: 'FactTitle',
})`
  width: 100%;
  border-radius: 5px 10px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  &:after {
    content: '';
    height: 10px;
    width: 80%;
    border-bottom: 1px solid gray;
  }
`

export const SideBarContainer = styled.div.attrs({
  className: 'SideBarContainer',
})`
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: gray;
  border-radius: 5px;
  > div {
    flex-grow: 1;
  }
`

export const SubtitleContainer = styled.div.attrs({
  className: 'SubtitleContainer',
})`
  color: black;
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
  background: #192225;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  // height: 60px;
  padding: 10px;
  background: lightgray;
`

export const Title = styled.div.attrs({
  className: 'Title',
})`
  font-size: 20px;
  background: #222;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
`

export const TitleContainer = styled.div.attrs({
  className: 'TitleContainer',
})`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #192225;
  padding: 10px;
  border-radius: 5px;
`

export const WidgetContainer = styled.div.attrs({
  className: 'WidgetContainer',
})`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  height: 100%;
  background: #192225;
  width: 100%;
  border-radius: 5px;
`
