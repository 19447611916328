import styled from 'styled-components'

export const FactContainer = styled.div.attrs({
  className: 'FactContainer',
})`
  display: flex;
  gap: 1em;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
  }
`

export const JobChartContainer = styled.div.attrs({
  className: 'JobChartContainer',
})`
  background: #192225;
  padding: 10px;
  border-radius: 5px;
`

export const Percent = styled.div.attrs({
  className: 'Percent',
})`
  width: 9ch;
  text-align: right;
`
