import { CiLogin, CiLogout } from 'react-icons/ci'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ICARDIO_AI_LOGO } from '../../constants/images'
import { authActionReducer } from '../../store/authentication/authSlice'
import Button from '../Button'
import {
  EmailContainer,
  HeaderContainer,
  HeaderWrapper,
  HeaderLogo,
  HeaderLinks,
  HeaderLinkItem,
  HeaderControls,
  IconContainer,
} from './Header.style'

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)

  const logOutUser = () => dispatch(authActionReducer.setUser(null))

  const userIsLoggedIn = !!user
  const isSuperuser = user?.isSuperuser

  const handleLogOutOnClick = (event) => {
    event.preventDefault()
    logOutUser()
    navigate('/')
  }

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <HeaderLogo>
          <Link to="/">
            <img src={ICARDIO_AI_LOGO} alt="logo" />
          </Link>
        </HeaderLogo>
        {/*<HeaderLinks>*/}
        {/*  <HeaderLinkItem>*/}
        {/*    <Link to="/keypoints">Keypointer</Link>*/}
        {/*  </HeaderLinkItem>*/}
        {/*  <HeaderLinkItem>*/}
        {/*    <Link to="/segmentations">Segmentor</Link>*/}
        {/*  </HeaderLinkItem>*/}
        {/*  <HeaderLinkItem>*/}
        {/*    <Link to="/studies">Studies</Link>*/}
        {/*  </HeaderLinkItem>*/}
        {/*  {isSuperuser && (*/}
        {/*    <HeaderLinkItem>*/}
        {/*      <Link to="/collections">Collections</Link>*/}
        {/*    </HeaderLinkItem>*/}
        {/*  )}*/}
        {/*</HeaderLinks>*/}
        <HeaderControls>
          {userIsLoggedIn ? (
            <>
              <EmailContainer>{user?.email}</EmailContainer>
              <div onClick={handleLogOutOnClick}>
                <Button
                  buttonType="bordered"
                  label={
                    <IconContainer>
                      <CiLogout /> Log Out
                    </IconContainer>
                  }
                />
              </div>
            </>
          ) : (
            <Link to="/login">
              <Button
                buttonType="bordered"
                label={
                  <IconContainer>
                    <CiLogin /> Log In
                  </IconContainer>
                }
              />
            </Link>
          )}
        </HeaderControls>
      </HeaderWrapper>
    </HeaderContainer>
  )
}

export default Header
