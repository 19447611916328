import styled from 'styled-components'

export const SpinnerContainer = styled.div.attrs({
  className: 'SpinnerContainer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #192225;
  border-radius: 5px;
  min-width: 300px;
`
