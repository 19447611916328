import { useEffect } from 'react'

import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import Button from '../Button'
import Input from '../Input'

import {
  ContainerBodyWrapper,
  ICardioLogo,
  LoginForm,
  LoginFormControl,
  LoginFormControlItem,
  LoginFormControlLabel,
} from './Login.style'

import { useDispatch } from 'react-redux'
import { authActionReducer } from '../../store/authentication/authSlice'

import { useForm } from 'react-hook-form'

import useAxios from 'axios-hooks'

import { AUTHORIZATION } from '../../constants/api'
import { ICARDIO_AI_LOGO } from '../../constants/images'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setUser = (payload) => dispatch(authActionReducer.setUser(payload))

  const [{ data, loading, error }, login] = useAxios(
    {
      method: 'post',
      url: AUTHORIZATION,
    },
    {
      manual: true,
      useCache: false,
    },
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = (data) => {
    login({
      data,
    })
      .then((r) => {
        console.log(r.data)
      })
      .catch((err) => {
        toast.error(err.response.data.detail)
      })
  }

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        trigger().then((rs) => {
          if (rs) {
            const { email, password } = getValues()
            onSubmit({
              email,
              password,
            })
          }
        })
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [])

  useEffect(() => {
    if (data && data.token) {
      setUser(data)
      navigate(`/`)
    }
  }, [data])

  return (
    <ContainerBodyWrapper>
      <LoginForm onSubmit={handleSubmit(onSubmit)}>
        <ICardioLogo src={ICARDIO_AI_LOGO} alt="logo" />

        <LoginFormControl>
          <LoginFormControlLabel>Enter your email</LoginFormControlLabel>
          <LoginFormControlItem>
            <Input
              placeholder="Email"
              name="email"
              {...register('email', {
                required: 'Please enter your email address',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Invalid email address',
                },
              })}
              errors={errors?.email?.message}
              onChange={(e) => {
                setValue('email', e.target.value, { shouldValidate: true })
              }}
            />
          </LoginFormControlItem>
        </LoginFormControl>
        <LoginFormControl>
          <LoginFormControlLabel>Enter your password</LoginFormControlLabel>
          <LoginFormControlItem>
            <Input
              placeholder="Password"
              type="password"
              name="password"
              {...register('password', { required: 'Please enter your password' })}
              errors={errors?.password?.message}
              onChange={(e) => {
                setValue('password', e.target.value, { shouldValidate: true })
              }}
            />
          </LoginFormControlItem>
          <Button
            style={{
              marginTop: '25px',
              width: '100%',
            }}
            loading={loading}
            fullwidth
            label={'Login'}
            buttonType="default"
            type="submit"
          />
        </LoginFormControl>
      </LoginForm>
    </ContainerBodyWrapper>
  )
}

export default Login
