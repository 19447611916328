import { Oval } from 'react-loader-spinner'
import { BlackCanvas, SpinnerContainer } from './Loader.style'

const Loader = () => (
  <SpinnerContainer>
    <BlackCanvas>
      <Oval
        visible={true}
        height="70"
        width="70"
        color="#222"
        secondaryColor="#aaa"
        ariaLabel="oval-loading"
        wrapperClass="SpinnerContainer"
      />
    </BlackCanvas>
  </SpinnerContainer>
)

export default Loader
