import { useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { KEYPOINT_COLLECTION_LABELS, KEYPOINT_JOBS } from '../../../../constants/api'
import { ActionsContainer, Button, ButtonsContainer } from './Actions.style'
import { useNavigate } from 'react-router-dom'

const Actions = ({
  dataIsLoading,
  dataset,
  diastolicFrame,
  frames,
  keypointJob,
  nextOnSave,
  setActiveFrameIndex,
  systolicFrame,
  setKeypointCollectionLabelLabels,
  setKeypointJob,
  updateKeypointJob,
  upsertKeypointCollections,
  pauseVideo,
}) => {
  const navigate = useNavigate()

  const keypointCollectionLabelsPayload = useMemo(
    () =>
      frames
        ?.filter((frame) => frame?.keypointLabels?.some((keypoint) => keypoint?.isLabeled))
        ?.map((frame) => {
          const phaseClass = frame?.isDiastolic ? 'Diastolic' : frame?.isSystolic ? 'Systolic' : null
          const frameUuid = frame?.uuid
          const keypointCollectionClass = dataset?.keypointCollectionClass?.uuid
          const keypointLabels = frame?.keypointLabels?.map((keypoint) => ({
            keypointClass: keypoint?.keypointClass?.uuid,
            x: keypoint?.x,
            y: keypoint?.y,
          }))

          return {
            phaseClass,
            frame: frameUuid,
            keypointCollectionClass,
            keypointLabels,
          }
        }),
    [dataset?.keypointCollectionClass?.uuid, frames],
  )
  const keypointJobPayload = useMemo(() => {
    const labeledSystolicKeypoints = systolicFrame?.keypointLabels?.filter((keypoint) => keypoint?.isLabeled)
    const labeledDiastolicKeypoints = diastolicFrame?.keypointLabels?.filter((keypoint) => keypoint?.isLabeled)
    const labeled =
      labeledSystolicKeypoints?.length === dataset?.keypointCollectionClass?.keypointClasses?.length &&
      labeledDiastolicKeypoints?.length === dataset?.keypointCollectionClass?.keypointClasses?.length
    const skipped = labeled ? false : keypointJob?.skipped
    return {
      labeled,
      skipped,
    }
  }, [
    dataset?.keypointCollectionClass?.keypointClasses?.length,
    diastolicFrame?.keypointLabels,
    keypointJob?.skipped,
    systolicFrame?.keypointLabels,
  ])

  const buttonIsDisabled = dataIsLoading
  const goToNextDicom = nextOnSave && keypointJob?.next

  const handleSaveOnClick = () => {
    if ((!systolicFrame || !diastolicFrame) && !window.confirm('Phases not labeled, continue?')) return

    const systolicKeypoints = systolicFrame?.keypointLabels?.filter((keypoint) => keypoint?.isLabeled)
    const diastolicKeypoints = diastolicFrame?.keypointLabels?.filter((keypoint) => keypoint?.isLabeled)

    if (
      (systolicKeypoints?.length < 5 || diastolicKeypoints?.lenfth < 5) &&
      !window.confirm('Missing some systolic and diastolic keypoints, continue?')
    )
      return

    upsertKeypointCollections({
      url: KEYPOINT_COLLECTION_LABELS,
      data: keypointCollectionLabelsPayload,
      method: 'POST',
    })?.then((response) => {
      if (!goToNextDicom) setKeypointCollectionLabelLabels(response?.data)
    })
    updateKeypointJob({
      url: `${KEYPOINT_JOBS}${keypointJob?.uuid}/`,
      data: keypointJobPayload,
      method: 'PATCH',
    })?.then((response) => {
      if (!goToNextDicom) setKeypointJob(response?.data)
    })
    if (goToNextDicom) {
      pauseVideo()
      setActiveFrameIndex(0)
      navigate(`/keypoints/${dataset?.uuid}/${keypointJob?.next}`)
    }
  }

  const handleSkipOnClick = () => {
    updateKeypointJob({
      url: `${KEYPOINT_JOBS}${keypointJob?.uuid}/`,
      data: {
        labeled: false,
        skipped: true,
      },
      method: 'PATCH',
    })?.then(() => {
      if (!keypointJob?.next) return
      pauseVideo()
      setActiveFrameIndex(0)
      navigate(`/keypoints/${dataset?.uuid}/${keypointJob.next}`)
    })
  }

  useHotkeys('p', (event) => {
    event?.preventDefault()
    handleSaveOnClick()
  })

  useHotkeys('L', (event) => {
    event?.preventDefault()
    handleSkipOnClick()
  })

  return (
    <ActionsContainer>
      {/* <TitleContainer>Actions</TitleContainer> */}
      <ButtonsContainer>
        <Button disabled={buttonIsDisabled} onClick={handleSaveOnClick}>
          (P) Save
        </Button>
        <Button disabled={buttonIsDisabled} onClick={handleSkipOnClick}>
          (L) Skip
        </Button>
      </ButtonsContainer>
    </ActionsContainer>
  )
}

export default Actions
