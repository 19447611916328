import { useParams, useNavigate, Link } from 'react-router-dom'
import { FaArrowCircleRight, FaArrowCircleLeft } from 'react-icons/fa'

import {
  ArrowIconContainer,
  Box,
  BoxContainer,
  BoxesContainer,
  DescriptionContainer,
  DicomCardContainer,
  DicomCardsContainer,
  DicomsContainer,
  DicomThumbnail,
  DicomVideoThumbnail,
  EmptyBox,
} from './Dicoms.style'

const Dicoms = ({
  dicomLabelingJobs,
  checkIfDicomIsLabeled,
  changesAreSaved,
  dicomSideBarIsOpen,
  setDicomSideBarIsOpen,
}) => {
  const { annotationSetId, studyLabelingJobId, dicomLabelingJobId } = useParams()
  const navigate = useNavigate()

  const dicomCards = dicomLabelingJobs?.map((dicomLabelingJob) => {
    const isActive = dicomLabelingJob?.uuid === dicomLabelingJobId
    const dicomLink = `/studies/${annotationSetId}/${studyLabelingJobId}/${dicomLabelingJob?.uuid}`

    const dicom = dicomLabelingJob.dicom

    const {
      viewLabelIsLabeled,
      phaseLabelsAreLabeled,
      keypointCollectionLabelsAreLabeled,
      segmentationsAreLabeled,
      phaseLabelsAreRequired,
      keypointCollectionLabelsAreRequired,
      segmentationsAreRequired,
      dicomIsLabeled,
    } = checkIfDicomIsLabeled(dicom)

    const handleDicomOnClick = (event) => {
      event.preventDefault()
      if (changesAreSaved || window.confirm('You have unsaved changes on this dicom, continue?')) navigate(dicomLink)
    }

    const emptyBox = <EmptyBox>-</EmptyBox>
    const nonRequiredBox = <EmptyBox>x</EmptyBox>

    return (
      <DicomCardContainer
        key={dicom?.index}
        isActive={isActive}
        onClick={handleDicomOnClick}
        isLabeled={dicomIsLabeled}
      >
        <Link to={dicomLink} onClick={(e) => e.preventDefault()}>
          {dicom?.mediaType?.toLowerCase().includes('video') ? (
            <DicomVideoThumbnail src={dicom?.file} alt="video-thumbnail" />
          ) : (
            <DicomThumbnail src={dicom?.file} alt="image-thumbnail" />
          )}
          <DescriptionContainer isHidden={!dicomSideBarIsOpen}>
            <div>
              [{String(dicom?.index + 1).padStart(2, '0')}] {dicom?.icid}
            </div>
            <BoxesContainer>
              <BoxContainer>
                {viewLabelIsLabeled ? <Box type="identifier" /> : emptyBox}
                {phaseLabelsAreLabeled ? <Box type="phaser" /> : phaseLabelsAreRequired ? emptyBox : nonRequiredBox}
                {keypointCollectionLabelsAreLabeled ? (
                  <Box type="keypointer" />
                ) : keypointCollectionLabelsAreRequired ? (
                  emptyBox
                ) : (
                  nonRequiredBox
                )}
                {segmentationsAreLabeled ? (
                  <Box type="segmentor" />
                ) : segmentationsAreRequired ? (
                  emptyBox
                ) : (
                  nonRequiredBox
                )}
              </BoxContainer>
              <BoxContainer>{dicomIsLabeled && <Box type="labeled" />}</BoxContainer>
            </BoxesContainer>
          </DescriptionContainer>
        </Link>
      </DicomCardContainer>
    )
  })

  const handleCollapseDicomSideBar = () => setDicomSideBarIsOpen(!dicomSideBarIsOpen)

  return (
    <DicomsContainer>
      <ArrowIconContainer onClick={handleCollapseDicomSideBar}>
        {dicomSideBarIsOpen ? <FaArrowCircleLeft size={50} /> : <FaArrowCircleRight size={50} />}
      </ArrowIconContainer>
      <DicomCardsContainer>{dicomCards}</DicomCardsContainer>
    </DicomsContainer>
  )
}

export default Dicoms
