import { memo, useEffect, useMemo } from 'react'

import FeatureOption from './FeatureOption'
import PhaseOption from './PhaseOption'
import { LabelsContainer, FeatureContainer, FeatureOptionsContainer, FeatureTitle } from './Labels.style'

import phaseClasses from './phaseClasses.json'

const Labels = ({
  activeFrame,
  systolicFrame,
  diastolicFrame,
  features,
  activeSegmentationClass,
  setSystolicFrame,
  setDiastolicFrame,
  setActiveSegmentationClass,
}) => {
  const phaseOptions = useMemo(
    () =>
      phaseClasses?.map((phaseClass, index) => {
        const hotkey = phaseClass?.[0]
        const setPhase = phaseClass === 'Systolic' ? setSystolicFrame : setDiastolicFrame
        const isSelected =
          phaseClass === 'Systolic'
            ? activeFrame?.index === systolicFrame?.index
            : activeFrame?.index === diastolicFrame?.index
        return (
          <PhaseOption key={index} hotkey={hotkey} label={phaseClass} setPhase={setPhase} isSelected={isSelected} />
        )
      }),
    [activeFrame?.index, diastolicFrame?.index, setDiastolicFrame, setSystolicFrame, systolicFrame?.index],
  )

  const featureOptions = useMemo(
    () =>
      features?.map((feature, index) => {
        const isActive = activeSegmentationClass === feature?.uuid
        const setActive = () => setActiveSegmentationClass(isActive ? null : feature?.uuid)
        const segmentation = activeFrame?.segmentations?.find(
          (segmentation) => segmentation?.segmentationClass?.uuid === feature?.uuid,
        )
        const isLabeled = !!segmentation?.paths
        return (
          <FeatureOption
            key={feature?.uuid}
            feature={feature?.value}
            hotkey={`${index + 1}`}
            isActive={isActive}
            isLabeled={isLabeled}
            setActive={setActive}
          />
        )
      }),
    [activeSegmentationClass, activeFrame?.segmentations, features, setActiveSegmentationClass],
  )

  useEffect(() => setActiveSegmentationClass(features?.[0]?.uuid), [activeFrame?.index, features, setActiveSegmentationClass])

  return (
    <LabelsContainer>
      {/* <TitleContainer>Labels</TitleContainer> */}
      <FeatureContainer>
        <FeatureTitle>Phase</FeatureTitle>
        <FeatureOptionsContainer>{phaseOptions}</FeatureOptionsContainer>
      </FeatureContainer>
      <FeatureContainer>
        <FeatureTitle>Features</FeatureTitle>
        <FeatureOptionsContainer>{featureOptions}</FeatureOptionsContainer>
      </FeatureContainer>
    </LabelsContainer>
  )
}

export default memo(Labels)
