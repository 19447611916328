import styled from 'styled-components'
import THEME from '../../../constants/theme'

export const CardContent = styled.div.attrs({
  className: 'CardContent',
})`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const CardFact = styled.div.attrs({
  className: 'CardFact',
})`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 5px;
`

export const CardTitle = styled.div.attrs({
  className: 'CardTitle',
})(
  ({ type }) => ` 
  background: ${type === 'KEYPOINTS' ? THEME.keypointLabels : type === 'SEGMENTATION' ? THEME.segmentations : THEME.bloodRed};
  font-weight: bold;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  text-align: center;
`,
)

export const SummaryCardContainer = styled.a.attrs({
  className: 'SummaryCardContainer',
})`
  all: unset;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  background-color: #212b2fcc;
  min-height: 170px;
  cursor: pointer;
  align-items: space-between;
  gap: 10px;
  padding-bottom: 10px;

  &:hover {
    opacity: 0.9;
  }
`

export const TypeTag = styled.div.attrs({
  className: 'TypeTag',
})(
  ({ type }) => `
    background: ${type === 'KEYPOINTS' ? THEME.keypointLabels : type === 'SEGMENTATION' ? THEME.segmentations : THEME.bloodRed};
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 10px;
    width: 100%;
    text-align: center;
  `,
)
