import Toggle from 'react-toggle'

import { Button, ButtonsContainer, ControlsContainer, ToggleContainer, ToggleSectionContainer } from './Controls.style'
import './Controls.style.css'

const Controls = ({ activeSegmentation, useOpenContours, setUseOpenContours, setActiveSegmentation }) => {
  const handleToggleOpenContours = () => setUseOpenContours((useOpenContours) => !useOpenContours)
  const handleDeleteOnClick = () => {
    console.log(activeSegmentation)
    setActiveSegmentation({
      ...activeSegmentation,
      isLabeled: false,
      paths: [],
    })
  }

  return (
    <ControlsContainer>
      <ToggleSectionContainer>
        <ToggleContainer onClick={handleToggleOpenContours}>
          <div style={{ color: '#fff' }}>Use Open Contours</div>
          <Toggle checked={useOpenContours} onClick={handleToggleOpenContours} readOnly />
        </ToggleContainer>
      </ToggleSectionContainer>
      <ToggleSectionContainer>
        <ButtonsContainer>
          <Button onClick={handleDeleteOnClick}>Delete</Button>
        </ButtonsContainer>
      </ToggleSectionContainer>
    </ControlsContainer>
  )
}

export default Controls
