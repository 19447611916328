import Labels from './Labels'

const Phaser = ({ activeFrame, phaseClasses, dicom, phaseLabels, setPhaseLabel }) => {
  return (
    <div>
      <Labels
        activeFrame={activeFrame}
        phaseClasses={phaseClasses}
        dicom={dicom}
        phaseLabels={phaseLabels}
        setPhaseLabel={setPhaseLabel}
      />
    </div>
  )
}

export default Phaser
