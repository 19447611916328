import { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import useAxios from 'axios-hooks'

import Loader from './Loader'
import { STUDY_LABELING_JOBS } from '../../../../constants/api'
import {
  FactContainer,
  FactTitle,
  IconContainer,
  SideBar,
  StyledInput,
  SubtitleContainer,
  TableContainer,
  TableWrapper,
  TitleContainer,
} from './Table.style'
import './Table.style.css'
import { FaCheckCircle } from 'react-icons/fa'

const Table = ({ annotationSet, annotationSetsResponseIsLoading }) => {
  const { annotationSetId } = useParams()

  const [search, setSearch] = useState(null)

  const columns = useMemo(() => {
    const getStudyLink = (row) => `/studies/${annotationSetId}/${row?.uuid}/${row?.dicomLabelingJobUuid}`

    return [
      {
        name: 'ICID',
        sortable: true,
        cell: (row) => (
          <Link to={getStudyLink(row)} className="data-link">
            {row.studyIcid}
          </Link>
        ),
      },
      {
        name: 'Number of Dicoms',
        sortable: true,
        cell: (row) => (
          <Link to={getStudyLink(row)} className="data-link">
            {row.numberOfDicomLabelingJobs}
          </Link>
        ),
      },
      {
        name: 'Number of Uploaded Dicoms',
        sortable: true,
        cell: (row) => (
          <Link to={getStudyLink(row)} className="data-link">
            {row.numberOfUploadedDicoms}
          </Link>
        ),
      },
      {
        name: 'Number of Labeled Dicoms',
        sortable: true,
        cell: (row) => (
          <Link to={getStudyLink(row)} className="data-link">
            {row.numberOfCompletedDicomLabelingJobs}
          </Link>
        ),
      },
      {
        name: 'Labeled',
        sortable: true,
        cell: (row) => (
          <Link to={getStudyLink(row)} className="data-link">
            <IconContainer>{row.status === 'LABELED' && <FaCheckCircle />}</IconContainer>
          </Link>
        ),
      },
    ]
  }, [annotationSetId])

  const params = useMemo(
    () => ({
      annotation_set: annotationSetId,
      paginate: false,
    }),
    [annotationSetId],
  )

  const [{ data, loading }] = useAxios(
    {
      url: STUDY_LABELING_JOBS,
      params,
    },
    {
      autoCancel: false,
      useCache: false,
    },
  )

  const isLoading = annotationSetsResponseIsLoading || loading
  const count = useMemo(() => data?.length, [data])
  const results = useMemo(() => {
    if (!search) return data
    return data?.filter((study) => study?.uuid?.toLowerCase()?.includes(search?.toLowerCase()))
  }, [data, search])

  const handleSearchOnChange = (e) => setSearch(e.target.value)

  return (
    <TableContainer>
      <SideBar>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TitleContainer>{annotationSet?.name}</TitleContainer>
            <SubtitleContainer>
              <FactTitle>Collection Summary</FactTitle>
              <FactContainer>
                <div>Total Studies</div>
                <div>{annotationSet?.numberOfStudyLabelingJobs}</div>
              </FactContainer>
              <FactContainer>
                <div>Labeled Studies</div>
                <div>{annotationSet?.numberOfCompletedStudyLabelingJobs}</div>
              </FactContainer>
              <FactContainer>
                <div>Remaining Studies</div>
                <div>
                  {annotationSet?.numberOfStudyLabelingJobs - annotationSet?.numberOfCompletedStudyLabelingJobs}
                </div>
              </FactContainer>
            </SubtitleContainer>
          </>
        )}
      </SideBar>
      <TableWrapper>
        <DataTable
          columns={columns}
          data={results}
          pagination
          paginationTotalRows={count}
          paginationDefaultPage={1}
          progressPending={isLoading}
          progressComponent={<Loader />}
          persistTableHead
        />
        {!isLoading && <StyledInput placeholder="Search..." value={search || ''} onChange={handleSearchOnChange} />}
      </TableWrapper>
    </TableContainer>
  )
}

export default Table
